import { render, staticRenderFns } from "./SolutionTpayVideos.vue?vue&type=template&id=30e351a8&scoped=true&"
var script = {}
import style0 from "./SolutionTpayVideos.vue?vue&type=style&index=0&id=30e351a8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e351a8",
  null
  
)

export default component.exports